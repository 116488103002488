import 'normalize.css'
import 'styles/index.scss'

const ScrollSync = {
  init: () => {
    document.querySelectorAll('.scrollable-container').forEach((container) => {
      const sections = container.querySelectorAll('.scrollable-section')

      sections.forEach((section) => {
        section.removeAttribute('data-ss-tall')
        section.removeAttribute('data-ss-small')
      })

      let tall = sections[0]
      let small = sections[1]

      if (sections[1].scrollHeight > sections[0].scrollHeight) {
        tall = sections[1]
        small = sections[0]
      }

      tall.setAttribute('data-ss-tall', tall.scrollHeight)
      small.setAttribute('data-ss-small', small.scrollHeight)

      container.style.height = `${tall.scrollHeight}px`
      container.setAttribute('data-ss-offset-top', container.offsetTop)
    })
  },

  handleScroll: () => {
    document.querySelectorAll('.scrollable-container').forEach((container) => {
      const viewportHeight = window.innerHeight
      const scrollTop = window.scrollY
      const offsetTop = parseInt(container.dataset.ssOffsetTop)

      const tall = container.querySelector('[data-ss-tall]')
      const small = container.querySelector('[data-ss-small]')

      const tallHeight = parseInt(tall.dataset.ssTall)
      const smallHeight = parseInt(small.dataset.ssSmall)

      const elementOffsetTop = offsetTop + viewportHeight
      const distance = scrollTop + viewportHeight - elementOffsetTop
      const progress = distance / (tallHeight - viewportHeight)

      const tallTop = offsetTop - scrollTop
      const smallTop = progress * (smallHeight - viewportHeight)

      tall.scrollTop = -tallTop
      small.scrollTop = smallTop
    })
  },
}

const init = () => {
  let currentRequest

  ScrollSync.init()
  ScrollSync.handleScroll()

  window.addEventListener('scroll', function() {
    cancelAnimationFrame(currentRequest)
    currentRequest = requestAnimationFrame(ScrollSync.handleScroll)
  })

  window.addEventListener('resize', ScrollSync.init)
}

document.addEventListener('DOMContentLoaded', init)
